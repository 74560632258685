@tailwind base;
@tailwind components;
@tailwind utilities;
[x-cloak] {
  display: none !important;
}
#main_nav {
  position: fixed;
  top: 0;
  transition: transform 0.4s;
}
#main_nav.nav--hidden {
  transform: translateY(-160px);
}
html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}
html.is-animating .transition-fade {
  opacity: 0;
}
